import { toast } from 'react-toastify';

export default class ToastHelper {
    static showSuccessToast(message) {
        toast.success(message, {
            autoClose: 3000, closeOnClick: true, theme: 'dark', pauseOnFocusLoss: false, pauseOnHover: false,
            bodyStyle: { fontSize: '16px', width: '100%' },
            closeButton: false,
            position: 'bottom-right'
        });
    }
};