import { NavLink, useNavigate } from 'react-router-dom';
import { FiChevronDown, FiSettings, FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import logo from '../../img/menuscan_logo3bg.png';
import { useAuth } from '../../components/auth';
import { useState, useEffect } from 'react';

export default function Navbar() {
    const [restaurantId, setRestaurantId] = useState(null);
    const [restaurantName, setRestaurantName] = useState(null);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const auth = useAuth();

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleLogout = () => {
        auth.logout();
        console.log('Wylogowano');
    };

    useEffect(() => {
        const storedRestaurantId = localStorage.getItem('restaurant_id');
        const storedRestaurantName = localStorage.getItem('restaurant_name');
        if (storedRestaurantId && storedRestaurantName) {
            setRestaurantId(storedRestaurantId);
            setRestaurantName(storedRestaurantName);
        } else {
            auth.logout();
            navigate('/login');
        }
    }, [navigate, auth]);

    return (
        <>
            <header className="w-full flex justify-between items-center px-6 bg-gray-800 text-white fixed top-0 left-0 z-50 shadow-md h-16">
                <div className="flex items-center space-x-4">
                    <NavLink to="/">
                        <img src={logo} alt="Logo" className="h-8 md:h-12" />
                    </NavLink>
                    <nav className="hidden md:flex items-center space-x-6 text-lg font-medium">
                        <NavLink
                            to="/"
                            className={({ isActive }) =>
                                `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Start
                        </NavLink>
                        <NavLink
                            to="/order-history"
                            className={({ isActive }) =>
                                `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Historia zamówień
                        </NavLink>
                        <NavLink
                            to="/statistics"
                            className={({ isActive }) =>
                                `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Statystyki
                        </NavLink>
                        <NavLink
                            to="/settings"
                            className={({ isActive }) =>
                                `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                            }
                        >
                            Ustawienia
                        </NavLink>
                    </nav>
                </div>
                <div className="flex items-center space-x-4">
                    {/* Mobile menu button */}
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={toggleMobileMenu}
                    >
                        <div className="relative w-6 h-6">
                            <FiMenu
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-0 rotate-90' : 'opacity-100 rotate-0'}`}
                            />
                            <FiX
                                size={24}
                                className={`absolute inset-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-90'}`}
                            />
                        </div>
                    </button>
                    {/* User dropdown */}
                    <div className="hidden md:block relative">
                        <button
                            onClick={toggleUserDropdown}
                            className="flex items-center space-x-2 text-lg focus:outline-none hover:text-gray-500"
                        >
                            <span>{restaurantName}</span>
                            <FiChevronDown
                                className={`transition-transform duration-300 ${isUserDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                            />
                        </button>
                        {isUserDropdownOpen && (
                            <ul className="absolute right-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md py-2 w-40 z-50">
                                <li
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                                    onClick={() => {
                                        navigate('/settings');
                                        toggleUserDropdown();
                                    }}
                                >
                                    <FiSettings />
                                    <span>Ustawienia</span>
                                </li>
                                <li
                                    onClick={handleLogout}
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                                >
                                    <FiLogOut />
                                    <span>Wyloguj</span>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </header>
            {/* Mobile menu */}
            <div
                className={`md:hidden bg-gray-800 text-white px-6 overflow-hidden fixed top-16 left-0 right-0 z-40 transition-max-height duration-500 ease-in-out ${isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'}`}
            >
                <nav className="flex flex-col space-y-4 text-lg font-medium py-4">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Start
                    </NavLink>
                    <NavLink
                        to="/order-history"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Historia zamówień
                    </NavLink>
                    <NavLink
                        to="/statistics"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Statystyki
                    </NavLink>
                    <NavLink
                        to="/settings"
                        className={({ isActive }) =>
                            `hover:text-gray-300 ${isActive ? 'text-blue-500' : ''}`
                        }
                        onClick={toggleMobileMenu}
                    >
                        Ustawienia
                    </NavLink>
                    <div className="border-t border-gray-700 mt-4 pt-4">
                        <div className="flex items-center space-x-2">
                            <span>{`Jan Kowalski - ID: ${restaurantId}`}</span>
                        </div>
                        <button
                            onClick={handleLogout}
                            className="flex items-center space-x-2 hover:text-gray-300 w-full text-left mt-4"
                        >
                            <FiLogOut />
                            <span>Wyloguj</span>
                        </button>
                    </div>
                </nav>
            </div>
        </>
    );
}
