import React from 'react';
import logo from '../img/menuscan_logo3bg.png';

const NotFound = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-900 text-gray-100">
            <div className="flex flex-col items-center">
                <img src={logo} alt="Logo" className="h-40 mb-5" />
                <div className="max-w-md p-6 border border-gray-700 rounded-md shadow-lg bg-gray-800 text-center">
                    <h1 className="text-4xl font-bold mb-4">404 Not Found</h1>
                    <p className="mb-4">Przepraszamy, strona, której szukasz, nie istnieje.</p>
                    <a href="/" className="font-bold text-gray-100 hover:underline">
                        Wróć do strony głównej
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
