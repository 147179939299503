import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiTable, FiClipboard, FiCalendar, FiChevronDown } from 'react-icons/fi';
import { Transition } from '@headlessui/react';
import { useAuth } from '../components/auth';
import Api from '../lib/Api';

function Home() {
  const [restaurantId, setRestaurantId] = useState(null);
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
  
    const [isTablesOpen, setIsTablesOpen] = useState(true);
    const [isOrdersOpen, setIsOrdersOpen] = useState(true);
    const [isReservationsOpen, setIsReservationsOpen] = useState(true);
  
    const auth = useAuth();
    const navigate = useNavigate();
  
    const toggleTables = () => {
      setIsTablesOpen(!isTablesOpen);
    };
  
    const toggleOrders = () => {
      setIsOrdersOpen(!isOrdersOpen);
    };
  
    const toggleReservations = () => {
      setIsReservationsOpen(!isReservationsOpen);
    };
  
    const fetchTables = useCallback(async () => {
      if (!restaurantId) {
        console.error('restaurantId nie jest ustawione');
        return;
      }
      try {
        const response = await Api.GetTables(restaurantId);
        if (response && Array.isArray(response)) {
          setTables(response);
        } else {
          console.error('Nieprawidłowa odpowiedź z API:', response);
          setTables([]);
        }
      } catch (error) {
        console.error('Błąd podczas pobierania stolików:', error);
        setTables([]);
      }
    }, [restaurantId]);
  
    useEffect(() => {
      const storedRestaurantId = localStorage.getItem('restaurant_id');
      if (storedRestaurantId) {
        setRestaurantId(storedRestaurantId);
      } else {
        auth.logout();
        navigate('/login');
      }
    }, [navigate, auth]);
  
    useEffect(() => {
      if (restaurantId) {
        fetchTables();
      }
    }, [restaurantId, fetchTables]);
  
    const openTableDetails = (table) => {
      setSelectedTable(table);
    };
  
    const closeTableDetails = () => {
      setSelectedTable(null);
    };

    return (
        <div className="min-h-screen bg-gray-900">
      {/* Main Content */}
      <div className="flex flex-col md:flex-row justify-center items-start mt-16 pt-4 px-4 space-y-4 md:space-y-0 md:space-x-4">
        {/* Tables Section */}
        <div className="w-full md:w-1/3 bg-gray-700 text-white rounded-lg shadow-lg">
          <button
            className="w-full flex items-center justify-between px-4 py-4 bg-gray-800 hover:bg-gray-700 focus:outline-none"
            onClick={toggleTables}
          >
            <div className="flex items-center space-x-2">
              <FiTable className="text-2xl" />
              <h2 className="text-xl font-bold">Stoliki</h2>
            </div>
            <FiChevronDown
              className={`transition-transform ${
                isTablesOpen ? 'rotate-180' : ''
              }`}
            />
          </button>
          <Transition
            show={isTablesOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              {tables.length > 0 ? (
                <ul className="space-y-2">
                  {tables.map((table) => (
                    <li
                      key={table.id}
                      onClick={() => openTableDetails(table)}
                      className="flex items-center justify-between p-3 bg-gray-800 rounded-md hover:bg-gray-600 cursor-pointer"
                    >
                      <span>{`Stolik ${table.table_number}`}</span>
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          table.status === 'available'
                            ? 'bg-green-500 text-white'
                            : 'bg-red-500 text-white'
                        }`}
                      >
                        {table.status}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-center">(puste)</p>
              )}
            </div>
          </Transition>
        </div>

        {/* Orders Section */}
        <div className="w-full md:w-1/3 bg-gray-700 text-white rounded-lg shadow-lg">
          <button
            className="w-full flex items-center justify-between px-4 py-4 bg-gray-800 hover:bg-gray-700 focus:outline-none"
            onClick={toggleOrders}
          >
            <div className="flex items-center space-x-2">
              <FiClipboard className="text-2xl" />
              <h2 className="text-xl font-bold">Zamówienia</h2>
            </div>
            <FiChevronDown
              className={`transition-transform ${
                isOrdersOpen ? 'rotate-180' : ''
              }`}
            />
          </button>
          <Transition
            show={isOrdersOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              <p className="text-center">(puste)</p>
            </div>
          </Transition>
        </div>

        {/* Reservations Section */}
        <div className="w-full md:w-1/3 bg-gray-700 text-white rounded-lg shadow-lg">
          <button
            className="w-full flex items-center justify-between px-4 py-4 bg-gray-800 hover:bg-gray-700 focus:outline-none"
            onClick={toggleReservations}
          >
            <div className="flex items-center space-x-2">
              <FiCalendar className="text-2xl" />
              <h2 className="text-xl font-bold">Rezerwacje</h2>
            </div>
            <FiChevronDown
              className={`transition-transform ${
                isReservationsOpen ? 'rotate-180' : ''
              }`}
            />
          </button>
          <Transition
            show={isReservationsOpen}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
            className="origin-top"
          >
            <div className="p-4 max-h-80 overflow-y-auto">
              <p className="text-center">(puste)</p>
            </div>
          </Transition>
        </div>
      </div>

      {/* Table Details Modal */}
      {selectedTable && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Transition
            show={true}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-75 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-200 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-75 opacity-0"
          >
            <div className="bg-white text-gray-800 p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-2xl mb-4 font-semibold">{`Szczegóły stolika ${selectedTable.table_number}`}</h2>
              <p className="mb-2">
                <span className="font-medium">Status:</span> {selectedTable.status}
              </p>
              <p className="mb-4">
                <span className="font-medium">ID:</span> {selectedTable.id}
              </p>
              <button
                onClick={closeTableDetails}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
              >
                Zamknij
              </button>
            </div>
          </Transition>
        </div>
      )}
    </div>
    );
}

export default Home;
