export default class Api {
    static api_url = 'https://menuscan.pl/api/v1';
    static x_api_key = 'test';

    static async RegisterUser(email, password, phone, restaurant_name) {
        const userData = {
            email: email,
            password: password,
            phone: phone,
            restaurant_name: restaurant_name
        };

        try {
            const response = await fetch(this.api_url + '/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                throw new Error('Błąd rejestracji: ' + response.statusText);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z rejestracją:', error);
            throw error;
        }
    }

    static async CheckDatabase(id) {
        try {
            const response = await fetch(this.api_url + '/restaurant/verify/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': localStorage.getItem('access_token'),
                    'x-api-key': this.x_api_key,
                },
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Błąd w CheckDatabase:', error);
            throw error;
        }
    }



    static async LoginUser(email, password, browserFingerprint) {
        const loginData = {
            email: email,
            password: password,
            browserFingerprint: browserFingerprint
        };

        try {
            const response = await fetch(this.api_url + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (!response.ok) {
                throw new Error('Błąd logowania: ' + response.statusText);
            }

            const data = await response.json();

            // Zapisz access_token i user_id w localStorage
            if (!data.error) {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('restaurant_id', data.restaurant_id);
            }

            return data; // Zwróć dane, aby można było je wykorzystać w handleLogin
        } catch (error) {
            console.error('Wystąpił problem z logowaniem:', error);
            throw error; // Przekaż błąd, aby obsłużyć go w handleLogin
        }
    }

    static async GetTables(id) {
        try {
            const response = await fetch(this.api_url + '/tables/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'access_token': localStorage.getItem('access_token'),
                    'x-api-key': this.x_api_key,
                },
            });

            if (!response.ok) {
                throw new Error('Błąd pobierania stolików: ' + response.statusText);
            }

            const data = await response.json();

            if (!data.error) {
                return data.tables; // Zwróć listę stolików
            }

            throw new Error('Błąd: ' + data.message);
        } catch (error) {
            console.error('Wystąpił problem z pobieraniem stolików:', error);
            throw error;
        }
    }

    static async GetUserInfo() {
        try {
            const response = await fetch(this.api_url + '/getuserinfo', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                },
            });

            if (!response.ok) {
                throw new Error('Błąd logowania: ' + response.statusText);
            }

            const data = await response.json();

            // Zapisz access_token i user_id w localStorage
            if (!data.error) {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('user_id', data.user_id);
            }

            return data; // Zwróć dane, aby można było je wykorzystać w handleLogin
        } catch (error) {
            console.error('Wystąpił problem z logowaniem:', error);
            throw error; // Przekaż błąd, aby obsłużyć go w handleLogin
        }
    }
}
