import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../lib/Api';
import ToastHelper from '../helpers/toast_helper';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState(''); // Dodaj stan dla telefonu
  const [restaurantName, setRestaurantName] = useState(''); // Dodaj stan dla nazwy restauracji
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!email || !password || !confirmPassword || !phone || !restaurantName) {
      setError('Wszystkie pola są wymagane.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Hasła muszą się zgadzać.');
      return;
    }

    try {
      const response = await Api.RegisterUser(email, password, phone, restaurantName);
      if (response.error === false && response.message === null) {
        ToastHelper.showSuccessToast('Zarejestrowano pomyślnie!');
        navigate('/login');
      } else {
        setError(response.message || 'Wystąpił błąd podczas rejestracji.');
      }
    } catch (error) {
      setError('Wystąpił błąd podczas łączenia z serwerem.');
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="max-w-md w-full bg-gray-800 p-6 rounded-md shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Zarejestruj się</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block mb-2 text-gray-300">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-300">Hasło:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-300">Potwierdź hasło:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-gray-300">Telefon:</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-gray-300">Nazwa restauracji:</label>
            <input
              type="text"
              value={restaurantName}
              onChange={(e) => setRestaurantName(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-green-600 hover:bg-green-700 text-white rounded"
          >
            Zarejestruj się
          </button>
        </form>
        <p className="mt-4">
          Posiadasz konto?{' '}
          <span
            className="font-bold text-green-400 cursor-pointer hover:underline"
            onClick={handleRegisterRedirect}
          >
            Zaloguj się
          </span>
        </p>
      </div>
    </div>
  );
}

export default Register;
