import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importujemy do nawigacji
import Fingerprint2 from 'fingerprintjs2';
import Api from '../lib/Api';
import { useAuth } from '../components/auth';
import ToastHelper from '../helpers/toast_helper';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError('Wszystkie pola są wymagane.');
      return;
    }

    const components = await Fingerprint2.getPromise();
    const values = components.map(component => component.value);
    const browserFingerprint = Fingerprint2.x64hash128(values.join(''), 31);

    try {
      const response = await Api.LoginUser(email, password, browserFingerprint);
      if (response.error === false) {
        ToastHelper.showSuccessToast('Zalogowano pomyślnie!');
        auth.login(response.user_id, response.access_token, response.restaurant_id, response.restaurant_name);

        navigate(`/`); // Przekierowanie z restaurant_id w URL
      } else {
        setError(response.message || 'Wystąpił błąd podczas logowania.');
      }
    } catch (error) {
      setError('Wystąpił błąd podczas łączenia z serwerem. Błąd: ' + error);
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register'); // Przekierowanie na stronę rejestracji
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="max-w-md w-full bg-gray-800 p-6 rounded-md shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Zaloguj się</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block mb-2 text-gray-300">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-gray-300">Hasło:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring focus:ring-green-500"
            />
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-green-600 hover:bg-green-700 text-white rounded"
          >
            Zaloguj
          </button>
        </form>
        <p className="mt-4">
          Nie masz konta?{' '}
          <span
            className="font-bold text-green-400 cursor-pointer hover:underline"
            onClick={handleRegisterRedirect}
          >
            Zarejestruj się
          </span>
        </p>
      </div>
    </div>
  );
}

export default Login;
