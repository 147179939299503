import React from 'react'
import { useAuth } from './auth'
import { Navigate } from 'react-router-dom'

export const RequireAuth = ({ children }) => {
    const auth = useAuth();

    console.log("RequireAuth - access_token:", auth.access_token);
    console.log("RequireAuth - user_id:", auth.user_id);
    console.log("RequireAuth - restaurant_id:", auth.restaurant_id);

    if (!auth.user_id || !auth.access_token || !auth.restaurant_id) {
        return <Navigate to="/login" />
    }

    return children;
}
