import { useState, useContext, createContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [access_token, setAccessToken] = useState(() => localStorage.getItem('access_token'));
    const [user_id, setUserId] = useState(() => localStorage.getItem('user_id'));
    const [restaurant_id, setRestaurantId] = useState(() => localStorage.getItem('restaurant_id'));
    const [restaurant_name, setRestaurantName] = useState(() => localStorage.getItem('restaurant_name'));

    const login = (user, access_token, restaurant_id, restaurant_name) => {
        console.log("Logging in:", user, access_token, restaurant_id);
        setUserId(user);
        setAccessToken(access_token);
        setRestaurantId(restaurant_id);
        setRestaurantName(restaurant_name);
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('user_id', user);
        localStorage.setItem('restaurant_id', restaurant_id);
        localStorage.setItem('restaurant_name', restaurant_name);
    };

    const logout = () => {
        console.log("Logging out");
        setUserId(null);
        setAccessToken(null);
        setRestaurantId(null);
        setRestaurantName(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('restaurant_id');
        localStorage.removeItem('restaurant_name');
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        const storedUser = localStorage.getItem('user_id');
        const storedRestaurantId = localStorage.getItem('restaurant_id');
        const storedRestaurantName = localStorage.getItem('restaurant_name');

        console.log("Stored Token:", storedToken);
        console.log("Stored User:", storedUser);
        console.log("Stored Restaurant ID:", localStorage.getItem('restaurant_id'));
        console.log("Stored Restaurant Name:", localStorage.getItem('restaurant_name'));

        if (storedToken && storedUser && storedRestaurantId && storedRestaurantName) {
            setAccessToken(storedToken);
            setRestaurantName(storedRestaurantName);
            setUserId(storedUser);
            setRestaurantId(storedRestaurantId);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ access_token, user_id, restaurant_id, restaurant_name, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};
